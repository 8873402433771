<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="personset">
        <div class="personset_con">
            <div class="personset_left">
                <p><span>*</span>姓名：</p>
                <p><span>*</span>手机号：</p>
                <!-- <p>备用手机号：</p> -->
                <p><span>*</span>邮箱：</p>
                <p><span>*</span>性别：</p>
                <p><span>*</span>生日：</p>
                <p><span>*</span>所属地区：</p>
                <p>微信号：</p>
                <p>钉钉号：</p>
                <p>身份证号：</p>
            </div>
            <div class="personset_right">
                <p><input type="text" placeholder="请输入真实姓名" v-model="name"></p>
                <p><input type="text" placeholder="请输入11位手机号" disabled v-model="phone1"><span class="chongxin_bangding" @click="genggai_dakai=true">重新绑定</span></p>
                <!-- <p><input type="text" placeholder="请输入11位备用手机号" v-model="phone2"></p> -->
                <p><input type="text" placeholder="请输入常用邮箱" v-model="email1"></p>
                <p>
                    <ul>
                        <li v-for="(i,index) in sex_list" @click="sex=index" :key="index"><img :src="sex==index?require('../../assets/red_quan.png'):require('../../assets/ccc_quan.png')" alt="">{{i}}</li>
                    </ul>
                </p>
                <p class="shengr_wode_shezhi">
                    <el-date-picker
                        v-model="sj"
                        type="date"
                        placeholder="选择日期"
                        @change="xuanze_shijian">
                    </el-date-picker>
                </p>
                <p><v-distpicker class="geren_shezhi_diqu" :province="temp.province" :city="temp.city" :area="temp.area" @selected="diqu_xuanze"></v-distpicker></p>
                <p><input type="text" placeholder="请输入微信号" v-model="vx"></p>
                <p><input type="text" placeholder="请输入钉钉号" v-model="dd"></p>
                <p><input type="text" placeholder="请输入身份证号" v-model="shenfen"></p>
            </div>
        </div>
        <div class="baocun" @click="dianji_baocun">
            保存
        </div>
        <div class="personset_beijing" v-if="genggai_dakai">
            <div class="personset_beijing_box">
                <p>更换手机后，下次登录使用新手机号登录，当前手机号：{{phone1}}</p>
                <input class="genghuan_shouji_yangshi" type="text" @blur="huoqu_shouji_zhuce" v-model="genghuan_phone" placeholder="请输入更换手机号">
                <p>
                    <input type="text" placeholder="请输入验证码" v-model="genghuan_code">
                    <span v-if="time==0" class="register_yanzheng" @click="dianji_yanzheng_ma">发送验证码</span>
                    <span v-if="time!=0" class="register_yanzheng">{{time+'s'}}</span>
                </p>
                <p>
                    <span @click="genggai_dakai=false">取消</span>
                    <span @click="dianji_genghuan_shouji_hao">确定</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { register_judge, sms, logout, change_user_mobile, get_my_info, update_user_info } from '../../api/api.js'
export default {
  name: 'personset',
  data () {
    return {
      name: '',
      phone1: '',
      phone2: '',
      email1: '',
      vx: '',
      dd: '',
      shenfen: '',
      sj: '',
      sex_list: ['男', '女'],
      sex: '0',
      time: '',
      temp: {
        province: '',
        city: '',
        area: ''
      },
      genghuan_phone: '',
      time: 0,
      genghuan_code: '',
      genggai_dakai: false, // true打开更改绑定手机号页面
      phone_zhuce: false
    }
  },
  mounted () {
  },
  created () {
    this.jichu()
  },
  watch: {
    genggai_dakai () {
      if (!this.genggai_dakai) {
        this.genghuan_phone = ''
        this.genghuan_code = ''
      }
    }
  },
  methods: {
    jichu () {
      this.phone1 = this.$jichuxinxi().mobile
      get_my_info({
        data: {
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10069) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.name = date.name
          this.email1 = date.email
          this.phone2 = date.mobile_add != undefined ? date.mobile_add : ''
          this.sex = date.sex == 1 ? '0' : '1'
          this.sj = date.date_birth
          if (date.area != undefined) {
            const zhi = date.area.split('-')
            this.temp.province = zhi[0]
            this.temp.city = zhi[1]
            this.temp.area = zhi[2]
          }
          this.vx = date.wechat != undefined ? date.wechat : ''
          this.dd = date.dingtalk != undefined ? date.dingtalk : ''
          this.shenfen = date.idcard != undefined ? date.idcard : ''
        } else if (res.data.code == 10070) {
          this.$message({
            message: '修改失败',
            type: 'error'
          })
        }
      })
    },
    //   倒计时
    dao () {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.time = TIME_COUNT
        this.timer = setInterval(() => {
          if (this.time > 0 && this.time <= TIME_COUNT) {
            this.time--
          } else {
            clearInterval(this.timer)
            this.timer = null
            this.time = 0
          }
        }, 1000)
      }
    },
    diqu_xuanze (data) {
      this.temp.province = data.province.value
      this.temp.city = data.city.value
      this.temp.area = data.area.value
    },
    // 时间小于10前面加0
    pa (s) {
      return s < 10 ? '0' + s : s
    },
    xuanze_shijian (date) {
      console.log(date)
      if (date.length != 0) {
        const d = date
        this.sj = d.getFullYear() + '-' + this.pa(d.getMonth() + 1) + '-' + this.pa(d.getDate())
        console.log(this.sj)
      }
    },
    // 点击保存
    dianji_baocun () {
      console.log()
      const date = this.$bendi_naqu_cunchu('mlbb_me_touxiang', '暂时')
      // update_user_info
      if (this.name.length != 0 && /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.email1) && this.sj.length != 0 && this.temp.province.length != 0) {
        console.log(this.time)
        update_user_info({
          data: {
            user_id: this.$jichuxinxi().user_id,
            name: this.name,
            email: this.email1,
            mobile_add: /^1[3456789]\d{9}$/.test(this.phone2) ? this.phone2 : null,
            sex: this.sex == 1 ? '0' : '1',
            date_birth: this.sj,
            area: this.temp.province + '-' + this.temp.city + '-' + this.temp.area,
            wechat: this.vx.length != 0 ? this.vx : null,
            dingtalk: this.dd.length != 0 ? this.dd : null,
            idcard: this.shenfen.length != 0 ? this.shenfen : null,
            avatar_data: date != undefined ? date.xin : null,
            avatar_data_type: date != undefined ? date.lei : null
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10067) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            get_my_info({
              data: {
                user_id: this.$jichuxinxi().user_id
              }
            }).then(ras => {
              console.log(ras)
              if (ras.data.code == 10069) {
                const data = JSON.parse(ras.data.body.data)
                console.log(data)
                this.$bendi_cunfang_cunchu('pc_mlbb_jichuxinxi', JSON.stringify(data), '暂时')
                // location.reload()
              } else if (ras.data.code == 10070) {}
            })
          } else if (res.data.code == 10068) {}
        })
      } else {
        this.$message({
          message: '未填完基础信息',
          type: 'warning'
        })
      }
    },
    dianji_yanzheng_ma () {
      if (/^1[3456789]\d{9}$/.test(this.genghuan_phone)) {
        if (!this.phone_zhuce) {
          this.dao()
          sms({
            data: { mobile: this.genghuan_phone }
          }).then(res => {
            if (res.data.code == 10000) {
              this.$message({
                message: '今日以超过最大值',
                type: 'warning'
              })
            } else if (res.data.code == 10001) {
              this.$message({
                message: '短信发送成功',
                type: 'success'
              })
            } else if (res.data.code == 10002) {
              this.$message({
                message: '短信发送失败',
                type: 'error'
              })
            }
          })
        } else {
          this.$message({
            message: '手机号已使用',
            type: 'error'
          })
        }
      } else {
        this.$message({
          message: '请填写正确的手机号',
          type: 'error'
        })
      }
    },
    huoqu_shouji_zhuce () {
      if (/^1[3456789]\d{9}$/.test(this.genghuan_phone)) {
        register_judge({
          data: {
            mobile: this.genghuan_phone
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10008) {
            // 可以注册
            this.phone_zhuce = false
          } else if (res.data.code == 10009) {
            this.phone_zhuce = true
            alert('手机号已使用')
          }
        })
      } else if (this.genghuan_phone.length != 0) {
        this.$message({
          message: '请填写完整信息',
          type: 'warning'
        })
      }
    },
    dianji_genghuan_shouji_hao () {
      if (this.genghuan_code.length != 0 && /^1[3456789]\d{9}$/.test(this.genghuan_phone)) {
        change_user_mobile({
          data: {
            user_id: this.$jichuxinxi().user_id,
            mobile: this.genghuan_phone + '',
            sms_code: this.genghuan_code + ''
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            logout({
              data: {
                mobile: this.phone1 + ''
              }
            }).then(res => {
              console.log(res)
              if (res.data.code == 10015) {
                this.$message({
                  message: '更换成功',
                  type: 'success'
                })
                sessionStorage.clear()
                // sessionStorage.setItem("pc_pc_wai_daohang", JSON.stringify(0));
                this.$router.push('/')
              } else if (res.data.code == 10016) {
                this.$message({
                  message: '更换失败',
                  type: 'error'
                }) 
              } else if (res.data.code == 11000) {
                this.$message({
                  message: '更换成功',
                  type: 'success'
                })
                sessionStorage.clear()
                // sessionStorage.setItem("pc_pc_wai_daohang", JSON.stringify(0));
                this.$router.push('/')
              }
            })
          } else if (res.data.code == 500) {} else if (res.data.code == 600) {
            this.$message({
              message: '手机号已使用',
              type: 'warning'
            })
          } else if (res.data.code == 10005) {
            this.$message({
              message: '验证码错误',
              type: 'warning'
            })
          }
        })
      } else {
        this.$message({
          message: '请填写完整信息',
          type: 'warning'
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './geren.scss'
</style>
